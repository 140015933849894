import {useForm} from "react-hook-form"
import axios from 'axios';
import * as React from "react";
import {
    Card, Row, Col, Alert,
} from "react-bootstrap";
import FormBottomButtons from "../shared/form/FormBottomButtons";
import TextField from "../shared/form/TextField";
import {ValidationRules} from "../../helpers/ValidationRules";
import {useContext, useEffect, useState} from "react";
import {useNavigate, useParams} from 'react-router-dom';
import LoadingSpinner from "../shared/LoadingSpinner";
import {CarsContext} from "../../context/CarsContext";

function ManageCarForm() {
    let {carId} = useParams();
    const {setNeedUpdate }= useContext(CarsContext)
    const [isLoading, setIsLoading] = useState(true);
    const [globalError, setGlobalError] = useState(null);
    const navigate = useNavigate();
    const {
        register,
        handleSubmit,
        formState: {errors, isValid},
        watch,
        setValue,
    } = useForm({
        mode: "onBlur",
    });

    useEffect(() => {
        if (carId) {
            axios.get(`Car/GetCar/${carId}`)
                .then(response => {
                    const car = response.data.data;
                    setValue("mark", car.mark);
                    setValue("model", car.model);
                    setValue("registrationNumber", car.registrationNumber);
                    setValue("description", car.description);
                })
                .catch(err => {
                })
                .finally(() => {
                    setIsLoading(false);
                })
        } else {
            setIsLoading(false);
        }
    }, [carId, setValue])

    const onSubmit = (data) => {
        setGlobalError(null);
        const dataToSend = {
            mark: data.mark,
            model: data.model,
            registrationNumber: data.registrationNumber,
            description: data.description,
        }
        if (carId) {
            axios.post('/Car/EditCar', {...dataToSend, carId})
                .then(function (response) {
                    if (!response.data.success) {
                        setGlobalError(response.data.error);
                    }
                    setNeedUpdate(true);
                    navigate('/ListaSamochodow');
                })
                .catch(function (error) {
                    console.log(error?.response?.data?.error);
                });
        } else {
            axios.put('/Car/AddCar', dataToSend)
                .then(function (response) {
                    if (!response.data.success) {
                        setGlobalError(response.data.error);
                    }
                    setNeedUpdate(true);
                    navigate('/ListaSamochodow');
                })
                .catch(function (error) {
                    console.log(error?.response?.data?.error);
                });
        }


    }
    const watchDescription = watch("description");

    return (
        <div className="ManageCarForm">
            {isLoading && <LoadingSpinner/>}
            {!isLoading && (<Card>
                <Card.Header>{carId ? <h4>Edycja samochodu</h4> : <h4>Rejestracja nowego samochodu</h4>}</Card.Header>
                <Card.Body>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Row>
                            <Col>
                                <TextField
                                    errors={errors}
                                    fieldName="mark"
                                    register={register}
                                    validationRules={ValidationRules.Mark}
                                    placeholder="Podaj nazwę marki samochodu"
                                    label="Marka"
                                />
                            </Col>
                            <Col>
                                <TextField
                                    errors={errors}
                                    fieldName="model"
                                    register={register}
                                    validationRules={ValidationRules.Model}
                                    placeholder="Podaj nazwę modelu samochodu"
                                    label="Model"
                                />
                            </Col>
                        </Row>
                        <TextField
                            errors={errors}
                            fieldName="registrationNumber"
                            register={register}
                            validationRules={ValidationRules.RegistrationNumber}
                            placeholder="(Opcjonalne) Podaj numer rejestracyjny"
                            label="Numer rejestracyjny"
                        />
                        <TextField
                            errors={errors}
                            fieldName="description"
                            register={register}
                            validationRules={ValidationRules.CarDescription}
                            placeholder="(Opcjonalne) Podaj notatki dotyczące samochodu"
                            label="Notatki"
                            asType="textarea"
                        />
                        <div className="mb-3">
                            {watchDescription ? watchDescription.length : 0} / 9999 znaków
                        </div>

                        {globalError && <Alert variant="danger">{globalError}</Alert>}
                        <FormBottomButtons cancelButtonText="Anuluj" isValid={isValid}
                                           submitButtonText={carId ? "Zapisz zmiany" : "Dodaj"}/>
                    </form>
                </Card.Body>
            </Card>)}
        </div>
    )
}

export default ManageCarForm;