import React, {createContext, useState} from 'react';

export const CarsContext = createContext();

export const CarsProvider = ({children}) => {
    const [cars, setCars] = useState(null);
    const [needUpdate, setNeedUpdate] = useState(false);
    return (
        <CarsContext.Provider value={{cars, setCars, needUpdate, setNeedUpdate}}>
            {children}
        </CarsContext.Provider>
    );
};