import {Col, Row, Card, Button} from "react-bootstrap";
import {useContext} from "react";
import {UserContext} from "../../context/UserContext";
import {Link} from "react-router-dom";

function ProfileBaseInfo() {

    const { user } = useContext(UserContext);

    return(

        <div className="profile-base-info">
            <Card>
                <Card.Header>Dane użytkownika</Card.Header>
                <Card.Body>
                    <Row>
                        <Col xs={5} md={4}>Nazwa użytkownika</Col>
                        <Col className="mb-2" xs={7} md={8}>{user.userName}</Col>

                        <Col xs={5} md={4}>Adres email</Col>
                        <Col xs={7} md={8}>{user.email}</Col>


                    </Row>
                    <Row className="mt-4">
                        <Col>

                            <Button variant="primary" className="me-2">Zmień adres email</Button>
                            <Link className="navbar-brand" to="/Profil/ZmianaHasla">
                                <Button variant="primary">Zmień hasło</Button>
                            </Link>

                        </Col>
                    </Row>
                </Card.Body>
            </Card>

        </div>
    )
}

export default ProfileBaseInfo;