import {useState, useEffect} from 'react';
import axios from 'axios';

const useGetRepairsByCarId = (carId) => {
    const [response, setResponse] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await axios.get(`/Repair/GetRepairsByCarId/${carId}`);
                setResponse(result.data.data);
            } catch (error) {
                setError(error);
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, [carId]);

    return {response, error, loading};
};

export default useGetRepairsByCarId;
