import {Form} from "react-bootstrap";
import {Controller} from "react-hook-form";
import * as React from "react";
import DatePicker from "react-datepicker";

const FormDatePicker = ({control, fieldName, label}) => {

    return (
        <Form.Group className="mb-3 d-flex flex-column">
            <Form.Label>{label}</Form.Label>
            <Controller
                control={control}
                name={fieldName}
                render={({field}) => (
                    <DatePicker
                        dateFormat="yyyy-MM-dd"
                        className="form-control"
                        placeholderText='Data naprawy'
                        onChange={(date) => field.onChange(date)}
                        selected={field.value}
                    />
                )}
            />
        </Form.Group>
    )
}

export default FormDatePicker