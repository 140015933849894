import React from 'react';
import {Navigate} from "react-router-dom";
import {IsCookieExist} from "../../helpers/CookieHelper";

const PrivateRoute = ({children}) => {

    const isCookie = IsCookieExist();
    if (!isCookie) {
        return <Navigate to="/logowanie"/>;
    }

    return children;
};

export default PrivateRoute;