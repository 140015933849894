import { Button, Spinner } from 'react-bootstrap';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';

function FormBottomButtons({ isValid, submitButtonText, cancelButtonText, isLoading }) {
  const navigate = useNavigate();

  return (
    <div className="d-flex gap-3">
      <Button variant="primary" type="submit" disabled={!isValid || isLoading}>
        <Spinner animation="border" role="status" size="sm" className='me-2' hidden={!isLoading} />
        <span>{submitButtonText}</span>
      </Button>
      <Button variant="warning" type="button" onClick={() => navigate(-1)}>
        {cancelButtonText}
      </Button>
    </div>
  );
}

export default FormBottomButtons;
