import Cookies from "universal-cookie";
import authConstants from "../constants/AuthConstants";

const cookies = new Cookies();

export const setCookie = (token,neverLogout) => {
    var date = new Date()
    if(neverLogout){
        date.setDate(date.getDate() + 36500)
    }else{
        date.setDate(date.getDate() + 7)
    }


    cookies.set(authConstants.tokenName, token, cookieConfig(date));
    localStorage.setItem(authConstants.tokenName, JSON.stringify({
        token,
        expirationDate: date,
    }))
}

export const setInitialCookie = () => {
    var tokenFromLocalStorage = JSON.parse(localStorage.getItem(authConstants.tokenName));
    if (tokenFromLocalStorage) {
        cookies.set(authConstants.tokenName, tokenFromLocalStorage.token, cookieConfig(new Date(tokenFromLocalStorage.expirationDate)));
    }
}

export const IsCookieExist = () => {
    return cookies.get(authConstants.tokenName) !== undefined;
}

export const DeleteCookie = () => {
    cookies.remove(authConstants.tokenName);
    localStorage.removeItem(authConstants.tokenName);
}

const cookieConfig = (date) => {
    return {
        path: '/',
        expires: date,
        sameSite: "strict",
        secure: false //Change it during deployment}
    }
}