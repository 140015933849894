import {createBrowserRouter, Outlet} from "react-router-dom";
import LoginPage from "./views/auth/LoginPage";
import RegisterPageUser from "./views/auth/RegisterUserPage";
import App from "./App";
import ManageCarPage from "./views/car/ManageCarPage";
import DisplayCarsPage from "./views/car/DisplayCarsPage";
import CarDetailsPage from "./views/car/CarDetailsPage";
import ManageRepairPage from "./views/repair/ManageRepairPage";
import DisplayRepairsPage from "./views/repair/DisplayRepairsPage";
import PrivateRoute from "./components/auth/PrivateRoute";
import DisplayRepairsByCarId from "./components/repair/DisplayRepairsByCarId";
import ProfilePage from "./views/myProfile/ProfilePage";
import ChangePasswordPage from "./views/myProfile/ChangePasswordPage";

export const router = createBrowserRouter([
    {
        path: "/",
        element: <App/>,
        children: [
            {
                path: "/Logowanie",
                element: <LoginPage/>,
            },
            {
                path: "Rejestracja",
                element: <RegisterPageUser/>,
            },
            {
                path: "DodajSamochod",
                element:
                    <PrivateRoute>
                        <ManageCarPage/>
                    </PrivateRoute>,
            },
            {
                path: "EdytujSamochod/:carId",
                element:
                    <PrivateRoute>
                        <ManageCarPage/>
                    </PrivateRoute>,
            },
            {
                path: "ListaSamochodow",
                element:
                    <PrivateRoute>
                        <DisplayCarsPage/>
                    </PrivateRoute>,
            },
            {
                path: "Samochod/:carId",
                element:
                    <PrivateRoute>
                        <CarDetailsPage/>
                    </PrivateRoute>,
            },
            {
                path: "DodajNaprawe",
                element:
                    <PrivateRoute>
                        <ManageRepairPage/>
                    </PrivateRoute>,
            },
            {
                path: "EdytujNaprawe/:repairId",
                element:
                    <PrivateRoute>
                        <ManageRepairPage/>
                    </PrivateRoute>,
            },
            {
                path: "Naprawy",
                element:
                    <PrivateRoute>
                        <DisplayRepairsPage/>
                    </PrivateRoute>,
            },
            {
                path: "PelnaHistoriaNapraw/:carId",
                element:
                    <PrivateRoute>
                        <DisplayRepairsByCarId/>
                    </PrivateRoute>,
            },
            {
                path: "Profil",
                children: [
                    {
                        path: "",
                        element: <ProfilePage />,
                    },
                    {
                        path: "ZmianaHasla",
                        element: <ChangePasswordPage />,
                    },
                ],
                element:
                    <PrivateRoute>
                        <Outlet />
                    </PrivateRoute>,
            },

        ]
    },
]);