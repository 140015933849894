import DisplayRepairItem from "../../views/repair/DisplayRepairItem";
const DisplayRepairs = ({repairs,filterRepairs}) => {

    return (
        <div className="display-repairs">
            {
                repairs.map(repair => {
                    return <DisplayRepairItem key={repair.id} repair={repair} filterRepairs={filterRepairs}/>
                })
            }
        </div>
    )
}

export default DisplayRepairs;