import {useState, useEffect} from 'react';
import axios from 'axios';

const useGetRepairById = (repairId, isActive) => {
    const [response, setResponse] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await axios.get(`/Repair/GetRepair/${repairId}`);
                setResponse(result.data.data);
            } catch (error) {
                setError(error);
            } finally {
                setLoading(false);
            }
        };
        if (isActive)
            fetchData();
    }, [isActive, repairId]);

    return {response, error, loading};
};

export default useGetRepairById;
