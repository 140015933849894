import { useForm } from 'react-hook-form';
import axios from 'axios';
import * as React from 'react';
import { Form, Card } from 'react-bootstrap';
import FormBottomButtons from '../shared/form/FormBottomButtons';
import { useContext } from 'react';
import { UserContext } from '../../context/UserContext';
import { setCookie } from '../../helpers/CookieHelper';
import { useNavigate } from 'react-router-dom';
import { CarsContext } from '../../context/CarsContext';

function LoginForm() {
  const [error, setError] = React.useState(undefined);
  const [isProcessing, setIsProcessing] = React.useState(false);
  const { setUser } = useContext(UserContext);
  const { setNeedUpdate } = useContext(CarsContext);
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onTouched',
  });

  const onSubmit = (data) => {
    setIsProcessing(true);
    setError(undefined);
    const neverLogout = data.neverLogout;
    axios
      .post('/Auth/Login', data)
      .then(function (response) {
        const data = response.data;

        if (data.isSuccess) {
          setCookie(data.data.token, neverLogout);

          axios
            .get('/User')
            .then(function (response) {
              setUser(response.data.data);
              setNeedUpdate(true);
              navigate('/ListaSamochodow');
            })
            .catch(function (error) {});
        } else {
          setError(data.data);
        }
      })
      .finally(() => {
        setIsProcessing(false);
      })
      .catch(function (error) {
        if (error?.response?.status === 404) {
          setError(error.response.data.error);
        } else {
          console.log(error);
        }
      });
  };

  return (
    <Card>
      <Card.Header>Logowanie</Card.Header>
      <Card.Body>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Adres Email</Form.Label>
            <Form.Control
              type="text"
              placeholder="Podaj adress email"
              {...register('userName', {
                required: 'Podanie nazwy użytkownika albo adresu email jest wymagane',
              })}
              className="mb-1"
            />
            {errors.userName && <Form.Text className="text-danger">{errors.userName.message}</Form.Text>}
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label>Hasło</Form.Label>
            <Form.Control
              type="password"
              placeholder="Podaj hasło"
              {...register('password', {
                required: 'Podanie hasła jest wymagane',
                minLength: {
                  value: 6,
                  message: 'Minimalna długość hasła to 6 znaków',
                },
                maxLength: {
                  value: 64,
                  message: 'Maksymalna długość hasła to 64 znaki',
                },
              })}
              className="mb-1"
            />
            {errors.password && <Form.Text className="text-danger">{errors.password.message}</Form.Text>}
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Check type={'checkbox'} label={`Nie wylogowuj`} {...register('neverLogout')} />
          </Form.Group>

          <div className="alert alert-danger" hidden={!error}>
            {error}
          </div>

          <FormBottomButtons cancelButtonText="Anuluj" isValid={isValid} submitButtonText="Zaloguj się" isLoading={isProcessing} />
        </form>
      </Card.Body>
    </Card>
  );
}

export default LoginForm;
