import {useState, useEffect} from 'react';
import axios from 'axios';

const useRemoveCar = (carId) => {
    const [response, setResponse] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const removeCar = async () => {
            try {
                const result = await axios.delete(`/Car/DeleteCar/${carId}`);
                setResponse(result.data.isSuccess);
            } catch (error) {
                setError(error);
            } finally {
                setLoading(false);
            }
        };

        if (carId) removeCar();
    }, [carId]);

    return {response, error, loading};
};

export default useRemoveCar;
