import './App.css';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import 'react-datepicker/dist/react-datepicker.css';
import {Col, Container, Row} from "react-bootstrap";
import NavigationBar from "./components/navigation/NavigationBar";
import React, {useContext, useEffect, useState} from "react";
import {IsCookieExist} from "./helpers/CookieHelper";
import axios from "axios";
import {UserContext} from "./context/UserContext";
import {Outlet, useLocation} from "react-router-dom";
import LoadingSpinner from "./components/shared/LoadingSpinner";
import {CarsContext} from "./context/CarsContext";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBookOpen, faFileInvoiceDollar, faHandshake} from "@fortawesome/free-solid-svg-icons";

function App() {
    const {user, setUser} = useContext(UserContext);
    const {cars, setCars, setNeedUpdate, needUpdate} = useContext(CarsContext);
    const [isLoading, setIsLoading] = useState(true);
    let location = useLocation();
    const isRoot = location.pathname === '/'

    useEffect(() => {
            const fetchData = async () => {
                    try {
                        if (IsCookieExist()) {
                            const [userResponse, carsResponse] = await Promise.all([
                                axios.get('/User'),
                                axios.get('/Car/GetCars')
                            ]);
                            if (userResponse.data.isSuccess)
                                setUser(userResponse.data.data);

                            if (carsResponse.data.isSuccess)
                                setCars(carsResponse.data.data);
                        }
                    } catch
                        (error) {
                        console.error('Error fetching data:', error);
                    } finally {
                    }
                }
            ;

            fetchData();
        }, [setUser, setCars]
    );

    useEffect(() => {
        if (user && cars) setIsLoading(false);
        if (!user && !cars && !IsCookieExist()) setIsLoading(false);
    }, [cars, user])

    useEffect(() => {
        if (needUpdate) {
            setIsLoading(true);
            axios.get('/Car/GetCars')
                .then((response) => {
                    setCars(response.data.data);
                    setNeedUpdate(false);
                    setIsLoading(false);
                })
                .catch((error) =>
                    console.error('Error fetching data:', error)
                )
        }
    }, [needUpdate, setCars, setNeedUpdate])

    return (
        <div className="App">
            <Container>
                <NavigationBar/>
                {isRoot && (
                    <Row>
                        <Col xs={12} md={4} className="gap-2">
                            <div className="d-flex pb-2">
                                <FontAwesomeIcon icon={faHandshake} size={"2xl"} style={{margin: "auto"}}/>
                            </div>
                            <div className="text-center">
                                <h3>Całkowicie za darmo i bez reklam!</h3>
                                <p>Serwis jest całkowicie za darmo i nie zawiera żadnych reklam. </p>

                            </div>
                        </Col>
                        <Col xs={12} md={4} className="gap-2">
                            <div className="d-flex pb-2">
                                <FontAwesomeIcon icon={faFileInvoiceDollar} size={"2xl"} style={{margin: "auto"}}/>
                            </div>
                            <div className="text-center">
                                <h3>Faktury w jednym miejscu</h3>
                                <p>Faktury za twoje naprawy w jednym miejscu bez żadnych limitów!</p>
                            </div>
                        </Col>
                        <Col xs={12} md={4} className="gap-2">
                            <div className="d-flex pb-2">
                                <FontAwesomeIcon icon={faBookOpen} size={"2xl"} style={{margin: "auto"}}/>
                            </div>
                            <div className="text-center">
                                <h3>Pełna historia twojego samochodu</h3>
                                <p>Nie pamiętasz kiedy coś naprawiałeś? A może chcesz budować pewną historię samochodu by pokazać ją przy sprzedaży ?</p>
                                Załóż juz teraz darmowe konto.
                            </div>
                        </Col>
                    </Row>
                )}
                {isLoading && <LoadingSpinner/>}
                {!isLoading && !isRoot && <Outlet/>}
            </Container>
        </div>
    );
}

export default App;
