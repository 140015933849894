import DisplayCars from "../../components/car/DisplayCars";
import {Button, Card} from "react-bootstrap";
import {Link} from "react-router-dom";

const DisplayCarsPage = () => {
    return (
        <div className="displayCarsPage">
            <Card>
                <Card.Header className="d-flex justify-content-between">
                    <div className="">
                        <h3>Lista twoich samochodów</h3>
                    </div>
                    <div className="">
                        <Link to="/dodajSamochod">
                            <Button variant="primary">Dodaj samochód</Button>
                        </Link>
                    </div>

                </Card.Header>
                <Card.Body>
                    <DisplayCars/>
                </Card.Body>
            </Card>
        </div>
    )
}

export default DisplayCarsPage