import {Card, Col, Form, Row} from "react-bootstrap";
import TextField from "../shared/form/TextField";
import {Controller, FormProvider, useForm} from "react-hook-form";
import {ValidationRules} from "../../helpers/ValidationRules";
import * as React from "react";
import DatePicker from "react-datepicker";
import CarDropDown from "../car/CarDropDown";
import FormBottomButtons from "../shared/form/FormBottomButtons";
import {useNavigate, useParams} from "react-router-dom";
import axios from "axios";
import {format} from 'date-fns';
import useGetRepairById from "../../hooks/getRepairById";
import LoadingSpinner from "../shared/LoadingSpinner";

const ManageRepairForm = () => {
    let {repairId} = useParams();
    const navigate = useNavigate();

    const {response, loading} = useGetRepairById(repairId, repairId !== undefined);
    const isLoading = repairId !== undefined && !response && loading;
    const form = useForm({
        mode: "onBlur",
    });

    const {
        register,
        handleSubmit,
        formState: {errors, isValid},
        control,
        setValue
    } = form

    if (response && !loading) {
        setValue("shortName", response.shortName);
        setValue("description", response.description);
        setValue("repairDate", response.repairDate);
        setValue("milage", response.milage);
        setValue("amount", response.amount);
    }

    const onSubmit = (data) => {
        const formData = new FormData();
        formData.append("shortName", data.shortName);
        formData.append("milage", data.milage);
        formData.append("description", data.description);
        formData.append("carId", data.selectedCar[0].id);
        formData.append("amount", data.amount);

        const formattedDate = format(new Date(data.repairDate), 'yyyy-MM-dd');
        formData.append("repairDate", formattedDate);


        if (data.files && data.files.length > 0) {
            for (let i = 0; i < data.files.length; i++) {
                formData.append("files", data.files[i]);
            }
        }

        if (repairId) {
            formData.append("repairId", repairId)
            axios.post("/Repair/EditRepair", formData)
                .then(response => {
                    navigate('/Naprawy');
                })
                .catch(err => console.log(err.response.data.data))
        } else {
            axios.put("/Repair/AddRepair", formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                })
                .then(response => {
                    const isSuccess = response.data.isSuccess;
                    if (isSuccess) {
                        navigate('/Naprawy');
                    } else {

                    }
                })
                .catch(err => console.log(err));
        }
    }


    return (
        <div className="manage-repair-container">
            {isLoading && <LoadingSpinner/>}
            {!isLoading && (
                <Card>
                    <Card.Header>
                        <h4>Rejestracja nowej naprawy</h4>
                    </Card.Header>
                    <Card.Body>
                        <FormProvider {...form}>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <Row>
                                    <Col>
                                        <TextField
                                            errors={errors}
                                            fieldName="shortName"
                                            register={register}
                                            validationRules={ValidationRules.RepairName}
                                            placeholder="Podaj krótką nazwę naprawy"
                                            label="Krótka nazwa naprawy"
                                        />
                                    </Col>
                                    <Col>
                                        <TextField
                                            errors={errors}
                                            fieldName="milage"
                                            register={register}
                                            validationRules={ValidationRules.Milage}
                                            placeholder="Podaj przebieg samochodu"
                                            label="Przebieg samochodu"
                                            type="number"
                                        />
                                    </Col>
                                </Row>

                                <Row>
                                    <Col>
                                        <Form.Group className="mb-3 d-flex flex-column">
                                            <Form.Label>Data naprawy</Form.Label>
                                            <Controller
                                                control={control}
                                                name='repairDate'
                                                render={({field}) => (
                                                    <DatePicker
                                                        dateFormat="yyyy-MM-dd"
                                                        className="form-control"
                                                        placeholderText='Data naprawy'
                                                        onChange={(date) => field.onChange(date)}
                                                        selected={field.value}
                                                    />
                                                )}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <CarDropDown
                                            preselectedCarId={response ? response.carId : undefined}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <TextField
                                            errors={errors}
                                            fieldName="amount"
                                            register={register}
                                            validationRules={ValidationRules.Amount}
                                            placeholder="(Opcjonalne) Podaj kwotę naprawy"
                                            label="Kwota naprawy"
                                            type="number"
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <TextField
                                            errors={errors}
                                            fieldName="description"
                                            register={register}
                                            validationRules={ValidationRules.RepairDescription}
                                            placeholder="Podaj opis naprawy"
                                            label="Opis naprawy"
                                            asType="textarea"
                                        />
                                    </Col>
                                </Row>

                                <Form.Group controlId="formFileMultiple" className="mb-3">
                                    <Form.Label>Dodaj pliki związane z naprawą</Form.Label>
                                    <Controller
                                        name="files"
                                        control={control}
                                        defaultValue={[]}
                                        render={({field: {onChange}}) => (
                                            <Form.Control
                                                type="file"
                                                multiple
                                                onChange={(e) => onChange([...e.target.files])}
                                            />
                                        )}
                                    />
                                </Form.Group>

                                <FormBottomButtons cancelButtonText="Anuluj" isValid={isValid}
                                                   submitButtonText={repairId ? "Zapisz zmiany" : "Dodaj"}/>

                            </form>
                        </FormProvider>
                    </Card.Body>
                </Card>
            )}
        </div>
    );
}

export default ManageRepairForm