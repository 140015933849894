import {useState, useEffect} from 'react';
import axios from 'axios';

const useGetRepairs = (currentPage, itemsPerPage, filters) => {
    const [response, setResponse] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true); // Reset loading state
            const url = '/Repair/GetRepairs/'
            const filtersToSend = {
                ...filters,
                itemsPerPage,
                currentPage
            }
            try {
                const result = await axios.post(url,filtersToSend);
                setResponse({
                    repairs:result.data.data,
                    totalCount: result.data.totalCount,
                });
            } catch (error) {
                setError(error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [currentPage, itemsPerPage, filters]);

    return {response, error, loading};
};

export default useGetRepairs;
