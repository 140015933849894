import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const Prompt = ({title, body, onClickHandler, toggleModal, showModal}) => {

    if (showModal) {
        return (
            <div
                className="modal show"
                style={{display: 'block', backgroundColor: 'rgba(33, 37, 41,0.8)'}}
            >
                <Modal.Dialog>
                    <Modal.Header closeButton>
                        <Modal.Title>{title}</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        {body}
                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => toggleModal()}>Nie</Button>
                        <Button variant="primary" onClick={() => {
                            onClickHandler();
                            toggleModal();
                        }}>Tak</Button>
                    </Modal.Footer>
                </Modal.Dialog>
            </div>
        )
    } else {
        return (<></>)
    }

}

export default Prompt