import useGetRepairsByCarId from "../../hooks/getRepairsByCarId";
import {useContext, useRef} from "react";
import {CarsContext} from "../../context/CarsContext";
import {format} from "date-fns";
import {Link, useParams} from "react-router-dom";
import LoadingSpinner from "../shared/LoadingSpinner";
import {useReactToPrint} from "react-to-print";
import {Button, Card, CardBody, Col, Row} from "react-bootstrap";
import {faGauge, faMoneyBill1} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendar, faFileLines} from "@fortawesome/free-regular-svg-icons";


const DisplayRepairsByCarId = () => {

    const {carId} = useParams();
    const {response} = useGetRepairsByCarId(carId);
    const {cars} = useContext(CarsContext)
    const carName = cars.filter(x => x.id === Number(carId))[0].fullName

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        pageStyle: " height: 100vh; margin: 0 !important;padding: 0 !important; overflow: hidden;",
        documentTitle: `Historia samochodu ${carName}`,
    });

    if (response) {
        return <div>
            <Button variant="primary" onClick={handlePrint}>Drukuj</Button>
            <div ref={componentRef}>
                <h3 className="pb-2">Historia samochodu: {carName}</h3>


                {response.map(data => {
                    return (
                        <Card key={`repair${data.id}`} className="mb-3">
                            <CardBody>
                                <Row>
                                    <Col className="gap-2 flex-col d-flex">
                                        <div><FontAwesomeIcon icon={faFileLines}/></div>
                                        <div>{data.shortName}</div>
                                    </Col>

                                </Row>
                                <Row className="py-2">
                                    <Col className="new-line-support">
                                        <div>{data.description}</div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="gap-2 flex-col d-flex">
                                        <div><FontAwesomeIcon icon={faCalendar}/></div>
                                        <div>{format(data.repairDate, "yyyy-MM-dd")}</div>
                                    </Col>
                                    <Col className="gap-2 flex-col d-flex">
                                        <div><FontAwesomeIcon icon={faGauge}/></div>
                                        <div>{data.milage} km</div>
                                    </Col>
                                    <Col className="gap-2 flex-col d-flex">
                                        <div><FontAwesomeIcon icon={faMoneyBill1}/></div>
                                        <div>{data.amount ?? "Nie podano"} {data.amount ? "zł" : ""}</div>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    )
                })}
            </div>
            <Link to="/ListaSamochodow">
                <Button className="mt-3" variant="warning">Powrót</Button>
            </Link>
        </div>
    } else {
        return <LoadingSpinner/>
    }
}

export default DisplayRepairsByCarId