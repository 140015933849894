import {Container, Nav, Navbar, NavDropdown, Offcanvas} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUser} from '@fortawesome/free-solid-svg-icons'
import {useContext} from "react";
import {UserContext} from "../../context/UserContext";
import axios from "axios";
import {DeleteCookie} from "../../helpers/CookieHelper";
import {Link, useNavigate} from "react-router-dom";

function NavigationBar() {

    const {user, setUser} = useContext(UserContext);
    const navigate = useNavigate();

    const logout = () => {
        axios.post("/Auth/Logout", {})
            .then(response => {
                setUser(null);
                DeleteCookie();
                navigate('/');

            })
    }

    return (
        <Navbar collapseOnSelect key="menu" expand="md" className="bg-body-tertiary mb-3">
            <Container fluid>
                <Link className="navbar-brand" to="/">
                    <div className="d-flex">
                        <div className="logo me-2"></div>
                        <div>Car History</div>
                    </div>
                </Link>
                <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-menu`}/>
                <Navbar.Offcanvas
                    id={`offcanvasNavbar-expand-menu`}
                    aria-labelledby={`offcanvasNavbarLabel-expand-menu`}
                    placement="end"
                >
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title id={`offcanvasNavbarLabel-expand-menu`}>
                            Nawigacja
                        </Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <Nav className="justify-content-start flex-grow-1 pe-3">
                            <Nav.Link eventKey="cars"> <Link className="nav-link" to="ListaSamochodow">Samochody</Link></Nav.Link>
                            <Nav.Link eventKey="repairs"><Link className="nav-link" to="Naprawy">Naprawy</Link></Nav.Link>
                        </Nav>

                        {!user && <Nav className="pe-3">
                            <NavDropdown
                                title={<span><FontAwesomeIcon icon={faUser}/> Moje konto</span>}
                                id={`offcanvasNavbarDropdown-expand-menu`}
                            >
                                <Nav.Link eventKey="login"> <Link to="Logowanie" className="dropdown-item">
                                    Logowanie
                                </Link>
                                </Nav.Link>
                                <Nav.Link eventKey="register">
                                <Link to="Rejestracja" className="dropdown-item">
                                    Rejestracja
                                </Link>
                                </Nav.Link>

                            </NavDropdown>
                        </Nav>}
                        {user &&
                            <NavDropdown
                                title={<span>
                                    <FontAwesomeIcon icon={faUser}/> {user.userName}
                                </span>}
                                className="align-content-center"
                            >
                                <Link to="Profil" className="dropdown-item">
                                    Mój profil
                                </Link>
                                <NavDropdown.Item eventKey="logout" onClick={logout}>
                                    Wyloguj się
                                </NavDropdown.Item>
                            </NavDropdown>
                        }
                    </Offcanvas.Body>
                </Navbar.Offcanvas>
            </Container>
        </Navbar>
    )
}

export default NavigationBar