import {Button, Card, Pagination} from "react-bootstrap";
import {Link} from "react-router-dom";
import DisplayRepairs from "../../components/repair/DisplayRepairs";
import {useState} from "react";
import useGetRepairs from "../../hooks/getRepairs";
import {FormProvider, useForm} from "react-hook-form";
import RepairsFilter from "../../components/repair/RepairsFilter";
import LoadingSpinner from "../../components/shared/LoadingSpinner";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faScrewdriverWrench} from "@fortawesome/free-solid-svg-icons";

const DisplayRepairsPage = () => {
    const formMethods = useForm();
    const [filters, setFilters] = useState({carId: null, itemsPerPage: 1, currentPage: 0});
    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 10;
    const {response, loading} = useGetRepairs(currentPage, itemsPerPage, filters);

    console.count();

    const renderPaginationItems = () => {
        let items = [];
        if (currentPage > 0) items.push(
            <Pagination.Prev key="previous" onClick={() => setCurrentPage((prevState) => (prevState - 1))}/>
        )
        const numberOfPages = (response.totalCount / itemsPerPage);
        for (let i = 0; i < numberOfPages; i++) {
            items.push(
                <Pagination.Item key={i} active={i === currentPage} onClick={() => setCurrentPage(i)}>
                    {i + 1}
                </Pagination.Item>,
            );
        }
        if (currentPage < numberOfPages - 1) items.push(<Pagination.Next key="next"
                                                                         onClick={() => setCurrentPage((prevState) => (prevState + 1))}/>)
        return items;
    }

    const filterRepairs = () => {
        const selectedCar = formMethods.getValues("selectedCar");
        var selectedCarItem;
        if (!selectedCar || selectedCar.length === 0) {
            selectedCarItem = null
        } else {
            selectedCarItem = selectedCar[0].id
        }

        const filterObject = {
            carId: selectedCarItem,
            dateFrom: formMethods.getValues("dateFrom"),
            dateTo: formMethods.getValues("dateTo")
        }

        setFilters(filterObject);
    }

    return (
        <div className="displayCarsPage">
            <FormProvider {...formMethods}>
                <RepairsFilter filterRepairs={filterRepairs}/>

                <Card>
                    <Card.Header className="d-flex justify-content-between">
                        <div className="">
                            <h3>Lista twoich napraw</h3>
                        </div>
                        <div className="">
                            <Link to="/dodajNaprawe">
                                <Button variant="primary">Dodaj Naprawę</Button>
                            </Link>
                        </div>

                    </Card.Header>

                    <Card.Body>
                        {loading && !response && <LoadingSpinner/>}
                        {!loading && response && response.repairs.length > 0 && (
                            <>
                                <DisplayRepairs filterRepairs={filterRepairs} repairs={response.repairs}/>
                                <Pagination className="pt-4">
                                    {renderPaginationItems()}
                                </Pagination>
                            </>
                        )}
                        {!loading && response && response.repairs.length === 0 && (
                            <div className="d-flex align-content-center justify-content-center text-center">
                                <div>
                                    <FontAwesomeIcon size="2xl" icon={faScrewdriverWrench}/>
                                    <h4>Nie dodałeś jeszcze żadnych napraw</h4>

                                </div>
                            </div>
                        )}

                    </Card.Body>
                </Card>
            </FormProvider>

        </div>
    )
}

export default DisplayRepairsPage