import ChangePassword from "../../components/myProfile/ChangePassword";

function ChangePasswordPage() {
    return(
        <div>
            <ChangePassword />
        </div>
    )
}

export default ChangePasswordPage;