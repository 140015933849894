import {Card, Col, Collapse, Row, Form} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendar, faFileLines} from "@fortawesome/free-regular-svg-icons";
import {format} from "date-fns";
import {faCar, faCaretDown, faCaretUp, faFilePen, faGauge, faTrash} from "@fortawesome/free-solid-svg-icons";
import {useEffect, useState} from "react";
import LoadingSpinner from "../../components/shared/LoadingSpinner";
import useGetRepairById from "../../hooks/getRepairById";
import {useNavigate} from "react-router-dom";
import DisplayFilesList from "../../components/file/DisplayFilesList";
import Prompt from "../../components/shared/Prompt";
import useRemoveRepair from "../../hooks/RemoveRepairById";
import TooltipArea from "../../components/shared/TooltipArea";

const DisplayRepairItem = ({repair, filterRepairs}) => {
    const [displayDetails, setDisplayDetails] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [removeRepairId, setRemoveRepairId] = useState(undefined);

    const {response: removeRepairResponse} = useRemoveRepair(removeRepairId);

    const {loading, response} = useGetRepairById(repair.id, displayDetails)
    const navigate = useNavigate();

    useEffect(() => {
        if (removeRepairResponse) filterRepairs();
    }, [removeRepairResponse, filterRepairs])

    return (
        <>
            {showDeleteModal &&
                <Prompt body="Czy aby napewno chcesz usunąć tą naprawę?"
                        onClickHandler={() => setRemoveRepairId(repair.id)} title="Usunięcie naprawy"
                        showModal={showDeleteModal} toggleModal={() => setShowDeleteModal((prev) => !prev)}
                />}

            <Card key={repair.id} className="mb-3">
                <Card.Header>
                    <Row>
                        <Col xs={8}>
                            <div className="d-flex my-2">
                                <div className="me-1"><FontAwesomeIcon icon={faFileLines}/></div>
                                <div className="fw-bold d-none d-md-block me-1">Nazwa:</div>
                                <div>{repair.shortName}</div>
                            </div>
                        </Col>
                        <Col xs={4}>
                            <div className="d-flex my-2 justify-content-end">
                                <div className="me-1"><FontAwesomeIcon icon={faCalendar}/></div>
                                <div className="fw-bold d-none d-md-block me-1">Data naprawy:</div>
                                <div>{format(repair.repairDate, "yyyy-MM-dd")}</div>
                            </div>
                        </Col>
                        <Col xs={8}>
                            <Row>

                                <Col xs={6}>
                                    <div className="d-flex my-2">
                                        <div className="me-1"><FontAwesomeIcon icon={faCar}/></div>
                                        <div className="fw-bold d-none d-md-block me-1">Samochód:</div>
                                        <div>{repair.carName}</div>
                                    </div>
                                </Col>
                                <Col xs={6}>
                                    <div className="d-flex my-2">
                                        <div className="me-1"><FontAwesomeIcon icon={faGauge}/></div>
                                        <div className="fw-bold d-none d-md-block me-1">Przebieg:</div>
                                        <div>{repair.milage.toLocaleString()} km</div>
                                    </div>
                                </Col>
                            </Row>


                        </Col>
                        <Col xs={4}>
                            <div className="d-flex justify-content-end my-2">
                                <div className="mx-2">
                                    <TooltipArea text="Usunięcie naprawy">
                                        <FontAwesomeIcon icon={faTrash} size="xl"
                                                         onClick={() => setShowDeleteModal(true)}/>
                                    </TooltipArea>
                                </div>
                                <div className="mx-2">
                                    <TooltipArea text="Edycja naprawy">
                                        <FontAwesomeIcon icon={faFilePen} size="xl"
                                                         onClick={() => navigate(`/EdytujNaprawe/${repair.id}`)}/>
                                    </TooltipArea>

                                </div>
                                <div className="mx-2" onClick={() => setDisplayDetails((prev) => !prev)}>
                                    <TooltipArea
                                        text={displayDetails ? "Ukryj detale naprawy" : "Pokaż detale naprawy"}>
                                        <FontAwesomeIcon icon={displayDetails ? faCaretUp : faCaretDown} size="xl"/>
                                    </TooltipArea>
                                </div>
                            </div>
                        </Col>

                    </Row>
                </Card.Header>
                <Collapse in={displayDetails}>
                    <Card.Body>
                        {loading && <LoadingSpinner/>}
                        {!loading && (
                            <Row>
                                <Col xs={12} md={6}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Opis naprawy</Form.Label>
                                        <Form.Control as="textarea" disabled className="text-muted">
                                            {response.description ? response.description : "Brak opisu"}
                                        </Form.Control>
                                    </Form.Group>

                                    <Form.Group className="mb-3">
                                        <Form.Label>Koszt</Form.Label>
                                        <Form.Control type="text" disabled
                                                      value={response.amount ? response.amount + " zł" : "Nie podano"}/>


                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={6}>
                                    <DisplayFilesList files={response.files}/>
                                </Col>
                            </Row>
                        )}
                    </Card.Body>
                </Collapse>

            </Card>

        </>
    )
}

export default DisplayRepairItem;