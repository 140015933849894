import {useForm} from "react-hook-form"
import axios from 'axios';
import * as React from "react";
import {
    Card, Row, Col, Alert,
} from "react-bootstrap";
import FormBottomButtons from "../shared/form/FormBottomButtons";
import TextField from "../shared/form/TextField";
import {ValidationRules} from "../../helpers/ValidationRules";
import {useState} from "react";

function RegisterForm() {
    const [globalError, setGlobalError] = useState(null);
    const {
        register,
        handleSubmit,
        formState: {errors, isValid},
        watch,
    } = useForm({
        mode: "onBlur",
    });

    const onSubmit = (data) => {
        setGlobalError(null);
        const dataToSend = {
            password: data.password,
            userName: data.userName,
            email: data.email
        }
        axios.post('/Auth/Register', dataToSend)
            .then(function (response) {
                if (!response.data.success) {
                    setGlobalError(response.data.error);
                }
            })
            .catch(function (error) {
                console.log(error?.response?.data?.error);
            });
    }

    return (
        <Card>
            <Card.Header>Rejestracja nowego użytkownika</Card.Header>
            <Card.Body>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Row>
                        <Col>
                            <TextField
                                type="email"
                                errors={errors}
                                fieldName="email"
                                register={register}
                                validationRules={ValidationRules.Email}
                                placeholder="Podaj adres email"
                                label="Adres email"
                            />
                        </Col>
                        <Col>

                            <TextField
                                errors={errors}
                                fieldName="userName"
                                register={register}
                                validationRules={ValidationRules.RegisterUserName}
                                placeholder="Podaj nazwę użytkownika"
                                label="Nazwa użytkownika"
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <TextField
                                errors={errors}
                                type="password"
                                fieldName="password"
                                register={register}
                                validationRules={ValidationRules.Password}
                                placeholder="Podaj hasło"
                                label="Hasło"
                            />
                        </Col>
                        <Col>
                            <TextField
                                type="password"
                                errors={errors}
                                fieldName="repeatPassword"
                                register={register}
                                validationRules={{
                                    required: true,
                                    validate: (val) => {
                                        if (watch('password') !== val) {
                                            return "Hasła nie są jednakowe";
                                        }
                                    },
                                }}
                                placeholder="Powtórz hasło"
                                label="Powtórz hasło"
                            />
                        </Col>
                    </Row>
                    {globalError && <Alert variant="danger">{globalError}</Alert>}
                    <FormBottomButtons cancelButtonText="Anuluj" isValid={isValid}
                                       submitButtonText="Zarejestruj się"/>
                </form>
            </Card.Body>
        </Card>


    )
}

export default RegisterForm;