import {useFormContext} from "react-hook-form";
import {ValidationRules} from "../../helpers/ValidationRules";
import CarDropDown from "../car/CarDropDown";
import * as React from "react";
import {Button, Card, Col, Collapse, Row} from "react-bootstrap";
import FormDatePicker from "../shared/form/FormDatePicker";
import {faCaretDown, faCaretUp} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useState} from "react";

const RepairsFilter = ({filterRepairs}) => {
    const formMethods = useFormContext();
    const {register, control, formState: {errors}} = formMethods;
    const [displayDetails, setDisplayDetails] = useState(false)



    return (
        <div className="pb-4">
            <Card>
                <Card.Header className="d-flex justify-content-between"
                             onClick={() => setDisplayDetails((prev) => !prev)}>
                    <div className="">
                        <h4>Filtry</h4>
                    </div>
                    <div className="">
                        <FontAwesomeIcon icon={displayDetails ? faCaretUp : faCaretDown} size="xl"/>
                    </div>

                </Card.Header>
                <Collapse in={displayDetails}>
                    <Card.Body>
                        <Row>
                            <Col xs={12} md={6}>
                                <CarDropDown
                                    register={register}
                                    errors={errors}
                                    validationRules={ValidationRules.Mark}
                                    fieldName="selectedCar"
                                    isNullAvaiable={true}/>
                            </Col>
                            <Col xs={12} md={3}>
                                <FormDatePicker control={control} fieldName="dateFrom" label="Data od"/>
                            </Col>
                            <Col xs={12} md={3}>
                                <FormDatePicker control={control} fieldName="dateTo" label="Data do"/>
                            </Col>
                        </Row>
                        <div>
                            <div className="d-flex gap-3">
                                <Button variant="primary" type="button" onClick={() => filterRepairs()}>
                                    Filtruj
                                </Button>
                                <Button variant="warning" type="button">
                                    Wyczyść filtry
                                </Button>
                            </div>
                        </div>
                    </Card.Body>
                </Collapse>
            </Card>

        </div>
    )

}

export default RepairsFilter;