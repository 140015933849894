import {Form} from "react-bootstrap";
import * as React from "react";

const TextField = ({register, fieldName, label, validationRules, errors, placeholder, asType, type = "text"}) => {
    return (
        <Form.Group className="mb-3">
            <Form.Label>{label}</Form.Label>
            <Form.Control type={asType ? undefined : type}
                          placeholder={placeholder}
                          {...register(fieldName, validationRules)}
                          className="mb-1"
                          as={asType}
                          rows={4}
                          step={1}

            />
            {errors[fieldName] && (
                <Form.Text className="text-danger">
                    {errors[fieldName].message}
                </Form.Text>
            )}
        </Form.Group>
    )
}

export default TextField