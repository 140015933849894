import {Card, Col, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleInfo, faPenToSquare, faTrash} from "@fortawesome/free-solid-svg-icons";
import TooltipArea from "../../components/shared/TooltipArea";
import {Link} from "react-router-dom";
import useRemoveCar from "../../hooks/RemoveCar";
import {useContext, useEffect, useState} from "react";
import Prompt from "../../components/shared/Prompt";
import {CarsContext} from "../../context/CarsContext";

const DisplayCarsItem = ({car}) => {
    const [removeCarId, setRemoveCarId] = useState(undefined);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const {response} = useRemoveCar(removeCarId);
    const {setCars, cars} = useContext(CarsContext);

    useEffect(() => {
        if (response) {
            setCars(cars.filter(x => x.id !== car.id));
        }
    }, [response, car.id, cars, setCars])

    return (
        <>
            {showDeleteModal &&
                <Prompt body="Czy aby napewno chcesz usunąć samochód? To również usunie wszystkie jego naprawy."
                        onClickHandler={() => setRemoveCarId(car.id)} title="Usunięcie samochodu"
                        showModal={showDeleteModal} toggleModal={() => setShowDeleteModal((prev) => !prev)}
                />}

            <Card className="displayCarsItem mb-4">
                <Card.Body>
                    <Row>
                        <Col xs={6} md={12}>
                            <Col md={12}><h4>{car.mark} {car.model}</h4></Col>
                            <Col md={12}>{car.registrationNumber}</Col>
                        </Col>
                        <Col xs={6} md={12}>
                            <div className="d-flex gap-3 mt-2 justify-content-end">
                                <div>
                                    <Link to={`/Samochod/${car.id}`} className="link-no-style">
                                        <TooltipArea text="Informacje o samochodzie">
                                            <FontAwesomeIcon size="xl" icon={faCircleInfo}/>
                                        </TooltipArea>
                                    </Link>
                                </div>
                                <div>
                                    <Link to={`/EdytujSamochod/${car.id}`} className="link-no-style">
                                        <TooltipArea text="Edycja samochodu">
                                            <FontAwesomeIcon size="xl" icon={faPenToSquare}/>
                                        </TooltipArea>
                                    </Link>
                                </div>
                                <div>

                                    <TooltipArea text="Usuń samochód">
                                        <FontAwesomeIcon onClick={() => setShowDeleteModal(true)} size="xl"
                                                         icon={faTrash}/>
                                    </TooltipArea>
                                </div>
                            </div>
                        </Col>

                    </Row>
                </Card.Body>
            </Card>
        </>
    )
}

export default DisplayCarsItem