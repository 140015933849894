export const ValidationRules = {
    Password: {
        required: "Podanie hasła jest wymagane",
        minLength: {
            value: 6,
            message: "Minimalna długość hasła to 6 znaków"
        },
        maxLength: {
            value: 64,
            message: "Maksymalna długość hasła to 64 znaki"
        }
    },
    UserName: {
        required: "Podanie nazwy użytkownika albo adresu email jest wymagane"
    },
    RegisterUserName: {
        required: "Podanie nazwy użytkownika jest wymagane"
    },
    Email: {
        required: "Podanie adresu email jest wymagane",
        pattern: {
            value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            message: 'Podaj prawidłowy format adresu email',
        },
    },
    Mark: {
        required: "Podanie marki samochodu jest wymagane",
        maxLength: {
            value: 64,
            message: "Maksymalna długość  tekstu to 64 znaków"
        }
    },
    Model: {
        required: "Podanie modelu samochodu jest wymagane",
        maxLength: {
            value: 64,
            message: "Maksymalna długość  tekstu to 64 znaków"
        }
    },
    RegistrationNumber: {
        maxLength: {
            value: 16,
            message: "Maksymalna długość  tekstu to 16 znaków"
        }
    },
    CarDescription: {
        maxLength: {
            value: 9999,
            message: "Maksymalna długość  tekstu to 9999 znaków"
        }
    },
    RepairName: {
        required:"Podanie krótkiej nazwy jest wymanage",
        maxLength: {
            value: 128,
            message: "Maksymalna długość tekstu to 128 znaki"
        }
    },
    RepairDescription: {
        maxLength: {
            value: 9999,
            message: "Maksymalna długość tekstu to 9999 znaków"
        }
    },
    Milage: {
        required: "Przebieg jest wymagany"
    },
    Amount:{
        min: {
            value:0,
            message: "Kwota nie może być mniejsza od 0"
        }
    }
}

