import {Card, Col, Row, Table} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFilePdf} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import {FormProvider, useForm} from "react-hook-form";
import {useEffect, useState} from "react";
import TextField from "../shared/form/TextField";

const DisplayFileList = ({files}) => {
    const formMethods = useForm();
    const {watch, register, formState: {errors}} = formMethods
    const [filesToDisplay, setFilesToDisplay] = useState([...files]);


    const downloadFile = async (fileId, fileName) => {
        try {
            const response = await axios.get(`/File/Download/${fileId}`, {
                responseType: 'blob',
            });

            // Create a URL for the file blob
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName); // Specify the file name

            // Append the link to the body
            document.body.appendChild(link);

            // Programmatically click the link to trigger the download
            link.click();

            // Clean up by removing the link
            link.remove();
        } catch (error) {
            console.error('Error downloading the file', error);
        }
    };

    useEffect(() => {
        const subscription = watch((value) => {
            setTimeout(() => {
                if (value.searchText) {
                    const filesToShow = filesToDisplay.filter(x => x.fileName.includes(value.searchText))
                    setFilesToDisplay(filesToShow);
                } else {
                    setFilesToDisplay(files);
                }
            }, 500);

        });

        return () => subscription.unsubscribe();
    }, [watch, filesToDisplay, setFilesToDisplay,files]);

    return (
        <div className="fileList">
            {files && (
                <Card>
                    <Card.Header>Pliki związane z naprawą</Card.Header>
                    <Card.Body>
                        {files.length > 0 && (
                            <>
                                <Row>
                                    <Col>
                                        <FormProvider {...formMethods}>
                                            <TextField fieldName="searchText" label="Nazwa szukanego pliku"
                                                       register={register}
                                                       placeholder="Podaj nazwę szukanego pliku" errors={errors}/>
                                        </FormProvider>
                                    </Col>
                                </Row>
                                <Table bordered hover>
                                    <thead>
                                    <tr>
                                        <th>Typ</th>
                                        <th>Nazwa pliku</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        filesToDisplay.map(file => (
                                            <tr>
                                                <td><FontAwesomeIcon icon={faFilePdf}/></td>
                                                <td><b className="cursor-pointer"
                                                       onClick={() => downloadFile(file.id, file.fileName)}>{file.fileName}</b>
                                                </td>
                                            </tr>))
                                    }

                                    </tbody>
                                </Table>
                            </>
                        )}
                        {files.length === 0 && (
                            <p>Nie zostały dodane żadne pliki</p>
                        )}
                    </Card.Body>
                </Card>
            )}
        </div>
    )
}

export default DisplayFileList