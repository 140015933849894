import {Card, Form} from "react-bootstrap";
import * as React from "react";
import {useForm} from "react-hook-form";
import FormBottomButtons from "../shared/form/FormBottomButtons";
import axios from "axios";
import {useNavigate} from "react-router-dom";

function ChangePassword() {
    const [error, setError] = React.useState(undefined);
    const [isProcessing, setIsProcessing] = React.useState(false);
    const {
        register,
        handleSubmit,
        formState: { errors, isValid },
        watch
    } = useForm({
        mode: 'onTouched',
    });
    const navigate = useNavigate();

    const onSubmit = (data) => {
        setIsProcessing(true);
        setError(undefined);
        axios
            .post('/User/ChangePassword',
                {
                    oldPassword: data.oldPassword,
                    newPassword: data.newPassword
                })
            .then(function (response) {
                const data = response.data;

                if (data.isSuccess) {
                    navigate('/Profil');
                } else {
                    setError(data.data);
                }
            })
            .finally(() => {
                setIsProcessing(false);
            })
            .catch(function (error) {
                    setError(error.response.data.error);
            });
    };



    return(
        <form onSubmit={handleSubmit(onSubmit)}>
            <Card>
                <Card.Header>Zmiana hasła</Card.Header>
                <Card.Body>
                    <Form.Group className="mb-3" controlId="formBasicCurrentPassword">
                        <Form.Label>Aktualne hasło</Form.Label>
                        <Form.Control
                            type="password"
                            placeholder="Podaj aktualne hasło"
                            {...register('oldPassword', {
                                required: 'Podanie hasła jest wymagane',
                                minLength: {
                                    value: 6,
                                    message: 'Minimalna długość hasła to 6 znaków',
                                },
                                maxLength: {
                                    value: 64,
                                    message: 'Maksymalna długość hasła to 64 znaki',
                                },
                            })}
                            className="mb-1"
                        />
                        {errors.oldPassword &&
                            <Form.Text className="text-danger">{errors.oldPassword.message}</Form.Text>}
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicNewPassword">
                        <Form.Label>Nowe hasło</Form.Label>
                        <Form.Control
                            type="password"
                            placeholder="Podaj nowe hasło"
                            {...register('newPassword', {
                                required: 'Podanie hasła jest wymagane',
                                minLength: {
                                    value: 6,
                                    message: 'Minimalna długość hasła to 6 znaków',
                                },
                                maxLength: {
                                    value: 64,
                                    message: 'Maksymalna długość hasła to 64 znaki',
                                },
                            })}
                            className="mb-1"
                        />
                        {errors.newPassword &&
                            <Form.Text className="text-danger">{errors.newPassword.message}</Form.Text>}
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicRepeatNewPassword">
                        <Form.Label>Powtórz nowe hasło</Form.Label>
                        <Form.Control
                            type="password"
                            placeholder="Podaj nowe hasło"
                            {...register('repeatNewPassword', {
                                required: 'Podanie hasła jest wymagane',
                                validate: (val) => {
                                    if (watch('newPassword') !== val) {
                                        return "Hasła nie są jednakowe";
                                    }
                                },
                            })}
                            className="mb-1"
                        />
                        {errors.repeatNewPassword &&
                            <Form.Text className="text-danger">{errors.repeatNewPassword.message}</Form.Text>}
                    </Form.Group>
                    <div className="alert alert-danger" hidden={!error}>
                        {error}
                    </div>
                    <FormBottomButtons cancelButtonText="Anuluj" isValid={isValid} submitButtonText="Zatwierdź"
                                       isLoading={isProcessing}/>

                </Card.Body>
            </Card>
        </form>
    )
}

export default ChangePassword