import {Spinner} from "react-bootstrap";

const LoadingSpinner = () => {
    return <div className="d-flex justify-content-center">
        <div>
            <Spinner animation="border"/>
        </div>
        <div>
            Wczytywanie danych...
        </div>
    </div>
}

export default LoadingSpinner;