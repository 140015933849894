import {useContext} from "react";
import {Col, Row} from "react-bootstrap";
import DisplayCarsItem from "../../views/car/DisplayCarsItem";
import {CarsContext} from "../../context/CarsContext";
import LoadingSpinner from "../shared/LoadingSpinner";

const DisplayCars = () => {
    const {cars} = useContext(CarsContext);

    return (
        <div className="carsList">
            {!cars && <LoadingSpinner />}
            {cars && cars.length > 0 && (<Row>
                    {cars.map((car) => {
                            return <Col xs={12} sm={12} md={4} key={car.id}>
                                <DisplayCarsItem car={car}/>
                            </Col>
                        }
                    )}
                </Row>
            )}
            {cars && cars.length === 0 && (
                <h3 className="text-center">Jeszcze nie dodałeś żadnych samochodów </h3>
            )}
        </div>)


}


export default DisplayCars
