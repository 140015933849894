import {Link, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import axios from "axios";
import {Button, Card, Col, Row} from "react-bootstrap";
import LoadingSpinner from "../../components/shared/LoadingSpinner";

const CarDetailsPage = (params) => {
    let {carId} = useParams();
    const [car, setCar] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        axios.get(`Car/GetCar/${carId}`)
            .then(response => {
                setCar(response.data.data);
            })
            .catch(err => {
            })
            .finally(() => {
                setIsLoading(false);
            })
    }, [carId])

    return (
        <div className="CarDetailsPage">
            {isLoading && <LoadingSpinner/>}
            {!isLoading && (
                <Card>
                    <Card.Header>
                        <h3>Detale samochodu</h3>
                    </Card.Header>
                    <Card.Body>
                        <Row className="pb-2">
                            <Col xs={"6"} sm={"6"} md={"2"} className={"fw-bold"}>Marka i model</Col>
                            <Col xs={"6"} sm={"6"} md={"10"}>{car.mark} {car.model}</Col>
                        </Row>
                        <Row className="pb-2">
                            <Col xs={"6"} sm={"6"} md={"2"} className={"fw-bold"}>Numer rejestrawcyjny</Col>
                            <Col xs={"6"} sm={"6"} md={"10"}>{car.registrationNumber}</Col>
                        </Row>
                        <Row className="pb-2">
                            <Col xs={"6"} sm={"6"} md={"2"} className={"fw-bold"}>Ilość napraw</Col>
                            <Col xs={"6"} sm={"6"} md={"10"}>{car.numberOfRepairs}</Col>
                        </Row>
                        <Row className="pb-2">
                            <Col xs={"12"} sm={"12"} md={"2"} className={"fw-bold"}>Notatki</Col>
                            <Col xs={"12"} sm={"12"} md={"10"}><div className="new-line-support">{car.description}</div></Col>
                        </Row>
                        <div className="d-flex flex-row gap-2">
                            <Link to="/ListaSamochodow">
                                <Button className="mt-3" variant="warning">Powrót</Button>
                            </Link>
                            <Link to={`/PelnaHistoriaNapraw/${carId}`}>
                                <Button className="mt-3" variant="primary">Pokaż historię napraw</Button>
                            </Link>
                        </div>


                    </Card.Body>
                </Card>
            )}


        </div>
    )
}

export default CarDetailsPage;