import {Form} from "react-bootstrap";
import * as React from "react";
import {useContext, useState} from "react";
import {CarsContext} from "../../context/CarsContext";
import {Controller, useFormContext} from "react-hook-form";


const CarDropDown = ({preselectedCarId, isNullAvaiable = false}) => {
    const Typeahead = require('react-bootstrap-typeahead').Typeahead;
    const {cars} = useContext(CarsContext);

    const carsList = isNullAvaiable ? [{fullName: "Wszystkie", value: 0}, ...cars] : [...cars]
    const formMethods = useFormContext();
    const preselectedCar = preselectedCarId ? carsList.filter(x => x.id === preselectedCarId) : undefined

    const [isError, setIsError] = useState(false);

    return (
        <div className="car-drop-down">
            {
                cars != null && (
                    <Form.Group className="mb-3">
                        <Form.Label>Wybierz samochód</Form.Label>
                        <Controller
                            as={Typeahead}
                            control={formMethods.control}
                            name="selectedCar"
                            render={({field: {value, onChange}}) => (
                                <Typeahead
                                    id="cars-dropdrown"
                                    labelKey="fullName"
                                    options={carsList}
                                    placeholder="Wybierz samochód"
                                    clearButton
                                    onChange={(e) => {
                                        onChange(e)
                                        if(e.length === 0){
                                            setIsError(true);
                                        }else{
                                            setIsError(false);
                                        }
                                    }}
                                    defaultSelected={preselectedCar}
                                    selected={value}
                                />
                            )
                            }/>
                        {isError && (
                            <Form.Text className="text-danger">
                                Wybranie samochodu jest wymagane
                            </Form.Text>
                        )}
                    </Form.Group>
                )
            }
        </div>
    )
}

export default CarDropDown